/* Ant stuff that isn't being included because we're not using *their* form */
form .ant-select, form .ant-cascader-picker {
  width: 100%;
}

strong {
  font-weight: 600 !important;
}

#root,
.app {
  height: 100vh;
}

.app__logo {
  display: block;
}

.app__content {
  padding: 24px;
  background: white;
  overflow-y: scroll;
}

.logo {
  display: block;
  width: 60px;
  height: 60px;
}

.user-menu {
  padding: 15px;
}

.user-menu__avatar.ant-avatar {
  margin-right: 7px;
}

.user-menu__name {
  color: white;
}

.user-menu__dropdown .anticon-down {
  color: white;
}

.section-header {
  display: flex;
  align-items: center;
  margin: 0 0 1em 0;
}

.section-header__left {
  flex: 0 1 auto;
  justify-self: flex-start;
}

.section-header__center {
  flex: 1 0 0;
  text-align: center;
}

.section-header__right {
  flex: 0 0 auto;
  justify-self: flex-end;
}

.login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__form__error.ant-alert {
  margin: 0 0 15px 0;
}

.login__form__email,
.login__form__password {
  display: block;
  margin: 0 0 15px 0;
}

.user__avatar.ant-avatar {
  height: 1.25em;
  width: 1.25em;
  margin: 0 6px 0 0;
}

.client-comment {
  margin: 10px 0 10px -0.625em;
  padding-left: calc(1.25em + 5px);
}

.client-comment__user {
  margin-left: calc(-1.25em - 5px);
}

.client-comment__body {
  margin: 0;
}

.client-comment__footer {
  display: flex;
  align-items: center;
  margin: 3px 0 0 0;
}

.client-comment__action {
  margin: 0 7px 0 0;
}

.client-comment__created-at,
.client-comment__updated-at {
  font-size: 12px;
  color: #bbb;
}

.client-comment__updated-at {
  color: orange;
}

.client-comment__subcomments {
  border-left: #ddd 2px solid;
}

.dialog__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
}

.dialog {
  z-index: 9999 !important;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  padding: 25px;
}

.popover--modal {
  z-index: 9999 !important;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.formik-input-group {
  display: block;
  margin: 0 0 10px 0;
}

.formik-input-group__label {
  display: block;
  font-weight: 500;
}

.formik-input-group__sub-label {
  margin-left: 5px;
  color: lightgray;
}

.formik-input-group__error {
  display: block;
  font-size: 0.8rem;
  color: red;
}

.form__actions {
  margin-top: 20px;
}

.form__action {
  margin-right: 10px;
}

.rainbow-text {
  color: red;
  animation: hue-change 3s infinite linear;
}

.link--normal-text {
  color: rgba(0, 0, 0, 0.85);
}

@keyframes hue-change {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
