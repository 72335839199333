@keyframes flip {
  0% {
    transform: rotateX(0);
  }
  12.5% {
    opacity: 0.5;
  }
  25% {
    opacity: 1;
    transform: rotateX(180deg);
  }
  50% {
    opacity: 1;
    transform: rotateX(180deg);
  }
  62.5% {
    opacity: 0.5;
  }
  75% {
    opacity: 1;
    transform: rotateZ(180deg);
  }
  100% {
    transform: rotateZ(180deg);
  }
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 3px 0;
}

.spinner__parts {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px;
  transform: rotateZ(45deg);
}

.spinner--active .spinner__part {
  animation: flip 2s infinite ease-in-out;
}

.spinner__part {
  border-radius: 2px;
}

.spinner__part:nth-child(1) {
  background-color: #d92f6a;
  animation-delay: 0;
}

.spinner__part:nth-child(2) {
  background-color: #9f8abd;
  animation-delay: 50ms;
}

.spinner__part:nth-child(3) {
  background-color: #573e83;
  animation-delay: 100ms;
}

.spinner__part:nth-child(4) {
  background-color: #4a2d78;
  animation-delay: 150ms;
}
