
.header-filters {
  display: flex;
  flex-direction: column;
}

.header-filters__item {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 8px;
  align-items: center;
}

.header-filters__item label {
  margin-right: 8px;
  white-space: nowrap;
}